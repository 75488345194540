.ql-editor {
    min-height: 300px;
}

.xcloud-header {
    background-color: #ffffff !important;
    padding-left: 24px;
    box-shadow: 0 5px 10px -5px #dddddd;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    bottom: 0px;
}

.xcloud-header .logo {
    float: left;
    overflow: hidden;
    color: #000000d9;
    font-weight: 700;
    font-size: 18px;
    width: 200px;
    font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
    line-height: 64px;
    letter-spacing: -.18px;
    white-space: nowrap;
    text-decoration: none;
}

.xcloud-header .logo img {
    height: 32px;
    margin-right: 12px;
}

.xcloud-body {
    margin-top: 64px;
    background-color: #ffffff !important;
}

.xcloud-body .ant-layout-sider {
    background-color: #ffffff;
}

.xcloud-content-wrapper {
    height: calc(100vh - 64px);
    overflow: hidden;
    padding: 0px 18px 18px 18px;
    margin-left: 200px;
}

.xcloud-content-wrapper .breadcrumb {
    margin: 12px 0px;
}

.xcloud-content {
    background-color: #ffffff;
    padding: 12px;
    overflow: auto;
}