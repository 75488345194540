.login-poster {
    top: 70px;
    position: absolute;
    width: 600px;
    height: 520px;
    right: 50%;
    margin-left: -40px;
    background: url(../asserts/images/login-bg.png) 50% no-repeat;
    background-size: cover;
}

.login-area {
    top: 70px;
    position: absolute;
    width: 500px;
    left: 50%;
    margin-left: 40px;
}